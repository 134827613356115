body{
	font-family: Montserrat, sans-serif !important;
  background:#f6f5f5 !important;
}
.ReactModal__Overlay--after-open {
    z-index: 9991;
}
.ReactModal__Content.ReactModal__Content--after-open
{
	width: 30%;
    left: 39%;
	height:30%;
}
.shiftForm{
	width:100%;
}
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 14px !important;
}
label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
	margin-left: 22px;
}
.one{
	position:relative;

}
.one::before{
    position: absolute;
    content: '1';
    background-color: #f60;
    z-index: 999;
    border-radius: 50px;
    left: -100px;
    /* float: left; */
    margin: 40px;
    width: 20px;
    height: 20px;
    /* font: 13px/130px 'Barlow Semi Condensed', sans-serif; */
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    text-align: center;
    background: rgb(255 102 0);
    border-radius: 50%;
    animation: shadow-pulse 1s infinite;
    font-size: 14px;
    padding: -5px;
    justify-content: center;
    align-items: center;
    display: flex;
}


@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
.two{
	position:relative;

}
.two::before{
    position: absolute;
    content: '2';
    background-color: #f60;
    z-index: 999;
    border-radius: 50px;
    left: -100px;
    /* float: left; */
    margin: 40px;
    width: 20px;
    height: 20px;
    /* font: 13px/130px 'Barlow Semi Condensed', sans-serif; */
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    text-align: center;
    background: rgb(255 102 0);
    border-radius: 50%;
    animation: shadow-pulse 1s infinite;
    font-size: 14px;
    padding: -5px;
    justify-content: center;
    align-items: center;
    display: flex;
}


@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
.three{
	position:relative;

}
.three::before{
    position: absolute;
    content: '3';
    background-color: #f60;
    z-index: 999;
    border-radius: 50px;
    left: -100px;
    /* float: left; */
    margin: 40px;
    width: 20px;
    height: 20px;
    /* font: 13px/130px 'Barlow Semi Condensed', sans-serif; */
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    text-align: center;
    background: rgb(255 102 0);
    border-radius: 50%;
    animation: shadow-pulse 1s infinite;
    font-size: 14px;
    padding: -5px;
    justify-content: center;
    align-items: center;
    display: flex;
}


@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
.profilebg{
	position:relative;
}

.selected-date {
  background-color: yellow; /* Change to the color you want */
}

.profilebg:before{
	content: '';
    position: absolute;
    background-color: #f60;
    width: 100%;
    height: 70px;
	border-radius: 6px;
}
.formControl{
	width:100%;
    padding: 10px 10px 10px 8px;
    /* margin-top: 8px; */
    border: 1px solid #cbcbcb;
	font-family: Montserrat, sans-serif !important;
  border-radius: 4px;
}
.dropdown{
	font-family: Montserrat, sans-serif !important;
	
    text-align: left;

}
.roleForm{
	width:100%;
  margin-bottom: 10px;
}

.link{
  text-decoration: none;
}
.textleft{
  text-align: left;
}
.bgwhite{
  background-color: #fff;
}
.btnorange{
  background: rgb(255, 102, 0);
  padding: 12px 28px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
.btnorange{
  margin-top:24px;
}
.locationborder{
  border-radius: 10px !important;
  border: 1px solid #f3f3f3;
  box-shadow:none !important;
  margin-bottom: 20px;
}
.whitebackground{
   background:#fff;
   padding: 33px;
   border-radius: 8px;
}
.selectdrop{
  width: 100%;
  border: 1px solid rgb(213 213 213);
  /* padding: 17px; */
  border-radius: 4px;
  font-size: 16px;
  height: 44px;
	font-family: Montserrat, sans-serif !important;

  padding: 0 12px;
}
.labelname{
  font-size: 14px;
  font-weight: 500;
  color: #999;
}
.btnmain{
  background: rgb(255, 102, 0) !important;
	font-family: Montserrat, sans-serif !important;

  box-shadow: none !important;
  margin-top: 24px !important;
  height: 44px;
  font-weight: 600;
  padding: 0 27px;
}
.loc{
  width: 274px;
}
.rederror{
  color:red;
  font-size:13px;
}

.addbtn{
  float: right;
  font-size: 14px;
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 7px 16px;
  border: 1px solid rgb(215, 215, 215);
  margin-top: 8px;
  background: rgb(255, 255, 255);
}
.portallink{
  float: right;
  font-size: 14px;
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 7px 16px;
  border: 1px solid rgb(215, 215, 215);
  margin-top: 8px;
  background: rgb(255, 255, 255);
}
.editrole{
  padding: 7px 0px;
  font-size: 14px;
  font-family: Montserrat;
  margin-right: 11px;
  color: rgb(62, 195, 18);
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  border: 0px;
}
.disabled{
  opacity: 0.5;
  color: #a5a5a5 !important;
  cursor: default !important;

}
.deleterole{
  padding: 7px 0px;
    background: none;
    border: none;
    font-size: 14px;
    font-family: Montserrat;
    color: rgb(211, 12, 12);
    font-weight: 600;
    cursor: pointer;
}
.shiftcard{
  font-family: Montserrat, sans-serif !important;
  font-size: 16px !important;
  margin-bottom: 12px;
  padding-bottom: 9px;
  text-transform: capitalize;
}
.btnloc{
  border-top: 1px solid rgb(235, 235, 235);
  padding-top: 12px;
}
.shiftcardlabel{
  font-size: 12px !important;
  font-family: Montserrat, sans-serif !important;
  color: #b9b9b9;
}
.shiftdiv{
  margin-left:25px;
}
.coulam{
  position:relative;

}
.coulam:before{
  content: '';
  position: absolute;
  background-color: #f60;
  height: 7px;
  width: 7px;
  left: -20px;
  top: 5px;
  border-radius: 50px;

}
.coulam:after{
  content: '';
  position: absolute;
  /* background-color: #ff660024; */
  height: 64%;
  /* width: 2px; */
  left: -18px;
  top: 19px;
  border-left: 2px dashed #ff660038;

}
.coulam:last-child:after{
  content: '';
  position: absolute;
  /* background-color: #ff660024; */
  height: 64%;
  /* width: 2px; */
  left: -18px;
  top: 19px;
  border-left: 2px dashed #fff;

}
.ReactModal__Content.ReactModal__Content--after-open{
  margin:auto;
  border: none !important;
  text-align:center;
  box-shadow: 1px 1px 20px #7e7e7e;
  font-family: Montserrat, sans-serif !important;
  height:300px;
  
  width: 273px;
}
.ReactModal__Overlay--after-open{
   background-color: #0000003d !important;
}
.trashimg{
  width:50px;
  margin-top: 43px;
}
.yesbtn{
  background: #f60;
  padding: 6px 21px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  font-family: Montserrat, sans-serif !important;
  border: none;
  cursor:pointer;
}
.nobtn{
  font-family: Montserrat, sans-serif !important;
  font-size: 14px;
  background: #ffff;
  border: 1px solid #999;
  margin-left: 11px;
  padding: 5px 20px;
  cursor:pointer;
  border-radius: 4px;
}
.iconsdel{
  margin-top:20px;
}
.form-control{
  
  width: 100%;
  height: 44px;
  border: 1px solid #cdcdcd;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 12px;

}
.shifticons{
  width:35px;
}
.lgoo{
  position: relative;
  left: -20px;
  width: 100px;
  margin: 8px;

}
.HIDDEN{
	display:none;
}
.READ_ONLY{
	pointer-events: none;
}
.spacing{
  margin-bottom:21px !important;
}
.dataemp{
  width: 414px;
  margin: 21px 0 0 -4px;
  display: flex;
  /* justify-content: space-around; */
}
@media (max-width:768px){
  .dataemp{
    display: grid;
    justify-content: flex-end;
    width: 100%;
  }
}
.btnoo{
  background: #f60 !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  font-family: 'Montserrat' !important;
}

.menuitemss span{
  color:#000;
	font-family: Montserrat, sans-serif !important;
list-style:none;
}
.ankerremove{
  text-decoration: none;
}
.READ_WRITE{
  text-decoration: none;

}
.textyu{
  text-align: left !important;
}
.marginalign{
  margin-bottom:20px;
}
.typomain{
  font-family: Montserrat, sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  margin-bottom: 16px !important;
  margin-top: 27px !important;
}
label.labelname0p {
  font-size: 12px;
  font-weight: 600;
}
.btnfinal{
  float: right !important;
  margin-top: 24px !important;
}
.pagination{
  margin-top: 14px;
  margin-left: 13px;
  margin-bottom:15px;
  display: flex;
}
.pagination .btn-primary{
  padding: 7px 10px;
    border: 1px solid #999;
    margin: 3px;
    background: #fff;
    border-radius: 7px;  transition: all 0.2s ease-in-out;
}
.pagination .btn-primary:hover{
  background-color: #ebebeb;
  cursor: pointer;

}
.pagination .active.btn-primary{
   background-color: #ebebeb;
}
.logout{
  color: #000 !important;
    background: none !important;
    box-shadow: none !important;
    font-size: 23px !important;
}
.loader{
  position: fixed;
  top: 50%;
  left:50%;
}
.loader2{
  position: fixed;
  top: 40%;
  right:50%;
}
.loginbody{
  width: 364px;
  margin: auto;
  /* border: 1px solid #e1e1e1; */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 1px 30px #ededed;
  margin:30px auto !important;
}
.btnbig{
  background: #f60 !important;
    box-shadow: none  !important;
    font-weight: 600  !important;
    font-family: 'Montserrat'  !important;
}
.errrormeg{
  background: #ff00002e;
  padding: 9px;
  border: 1px solid red;
  border-radius: 3px;
  color: red;
  font-weight: 500;
}
.arrowmain{
  width: 50px;
  height: 50px;
  font-size: 18px !important;
}
.shpwdata{
  box-shadow: none !important; 
   text-align: left !important;
    font-family:Montserrat, sans-serif !important ;
    font-size: 14px !important;
    color: #999 !important;
     font-weight: 500 !important;
     padding-top: 0 !important;
     margin-top: 0 !important;
     padding-bottom: 11px !important;
     margin-bottom: 4px;
     border-bottom: 1px solid #e3e3e3 !important;
     border-radius: 0px !important;
}

.shpwdata2{
  box-shadow: none !important; 
   text-align: right !important;
    font-family:Montserrat, sans-serif !important ;
    font-size: 14px !important;
    color: #999 !important;
     font-weight: 500 !important;
     padding-top: 0 !important;
     margin-top: 0 !important;
}
.answer {
  float: right;
  color: #000 !important;
}
.fc .fc-toolbar.fc-header-toolbar {
  /* margin-bottom: 1.5em; */
  margin-top: 15px !important;
}
.fc .fc-daygrid-day-frame {
  min-height: 100%;
  position: relative;
  cursor: pointer;
}

.active{
 background:  #ff6600c9;
 color: #e78f54c9;
 display: list-item;
}
.formmenu{
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  font-family: 'Montserrat';
  border: 1px solid #dbdbdb !important;
  border-radius: 8px;
}
.labelmenu{
  font-size: 14px;
    display: block;
    font-weight: 600;
    margin-top: 9px;
}
textarea.textareamenu {
  width: 100%;
  height: 40px;
  font-family: 'Montserrat';
    padding: 6px;
    font-size: 16px;
}

.btncolor{
  background: #f60;
  color: #fff;
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat';
  border: none;
  border-radius: 4px;
  margin-top: 24px;
}
.h6textlink{
  font-size: 16px !important;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 10px 0;
  color: #000;
  text-decoration: none;
}
.ankerlink{
  color: #000;
  text-decoration: none;
  font-family: 'Montserrat';
}
.typoh6 h6{
  font-size: 16px !important;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 10px 0;
  color: #000;
  text-decoration: none;
}
.righteye{
  float:right;
}
tspan {
  font-size: 11px !important;
  font-weight: 600 !important;
}
/* .piechartmain{
  margin-top:-41px;
} */
table{
  font-family: 'Montserrat';

}
.headingresponce{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 22px;
}
.addButton{
  float: right;
  font-size: 14px;
  border: 1px solid #999;
  padding: 5px 20px;
  border-radius: 4px;
  color: #000;
  text-decoration: none;
  cursor:pointer;
}
.rightext{
  text-align: right !important;
}
.margintop{
  margin-top:32px;
}
.radiocont input:last-child{
  margin-left:20px;
}
.radiocont input:nth-child(2){
  margin-left:20px;
}
.cancelbtn{
  margin-left:20px !important;
  color:#000 !important;
  font-weight:600 !important;
  font-family: 'Montserrat' !important;

}
.redreq{
  color:red;
}
.radiocont{
  margin-left: -20px;
}

.radiocont label{
   cursor:pointer;
}

.relativeRange{
  position: relative;
}

.maxone{
  position: absolute;
  top: 51px;
  font-size: 12px;
  background: #226c0954;
  padding: 1px 8px;
  border-radius: 20px;
  color: #226c09;
  font-weight: 600;}
.maxtwo{
  position: absolute;
  top: 51px;
  font-size: 12px;
  background: #ff000030;
  padding: 1px 8px;
  border-radius: 20px;
  color: #c20e0e;
  font-weight: 600;
  right: 0;
}
.userImg .active{
  background-color: rgb(0 255 22);
    color: transparent;
    border-radius: 50%;
    font-size: 13px;
    font-weight: 500;
    margin: 7px;
    position: absolute;
    left: 66px;
    bottom: 6px;
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
}
@media (max-width:900px){
  .EditProfile{
    position: absolute;
    right: 50px;
  }
  .ProfileImg{
    justify-content: flex-start;
    display: flex;
  }
  .userImg .active{
    bottom: 5px !important;
  }
}

.carddesign{
  border-radius: 10px !important;
  box-shadow:none !important;
  background-color: #fff;
  padding:15px;
}
table, tr, td, th{
	font-family: Montserrat, sans-serif !important;
  font-size: 12px !important;
}
.helloicons{
  width: 21px;

}
.newcontrol{
  width: 20% !important;
  margin-left: 10px;
  margin-bottom: 12px;
  border: none !important;
  box-shadow: 1px 1px 21px #e5e5e5;
  padding-left:11px;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .newcontrol{
    width: 100% !important;
  }
  .positionrel{
    display: flex;
    align-items: baseline;
    justify-content: space-around;
  }
  .positionrel .bi-geo-alt {
    right: 64% !important;
    top: 8px !important;
}
}
.heighttable{
  height:500px;
  overflow: auto;
}
.rightalign{
  text-align: right;
}
.positionrel{
  position:relative;
}
/* .positionrel .bi-geo-alt{
  position:absolute;
  right: 36%;
  top: 9px;

} */
.bgwhite2{
  background-color: #fff;
  padding: 20px;
  border-radius: 14px;
  margin-bottom:20px;
}

input:focus-visible,select:focus-visible {
  outline:none !important;
}
select{
  cursor:pointer;
  
}
.marginalog{
  margin-top:20px;
}
.datemap3{
  margin:0 !important;
  margin-left: 15px !important;
  width: auto !important;
}
.form23{
  width:21% !important;
}
.addEmp{
  margin-top: 24px;
}
.notificationdropdown{
  width: 33%;
}
.notificationdropdown .MuiPaper-root{
  max-height: 36rem;
  overflow: auto;
  position: fixed;
  right: 0;
  overflow-x: hidden;
}

@media only screen and (max-width: 480px) and (min-width: 200px){
  .css-6wz25k-MuiDrawer-docked .MuiDrawer-paper{
      position: absolute !important;
      width: 0;
  }
  .css-cvumne-MuiDrawer-docked .MuiDrawer-paper{
      position: absolute !important;
      z-index: 999999;
  }
  .desktopshow{
     display: none;
  }
  .mobileshow{
      display:block !important;
  }
}
@media (max-width:768px){
  .SelectColumn{
      margin-left: 16px !important;
  }
}

.colrolink{
  color:#f60 !important;
}

.selected-date {
  background-color: #d2b48c !important;
}

